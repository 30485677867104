$primary: #c9345c;
$use-notification-fonts: true !default;

/***********/
/** Fonts **/
/***********/

@if($use-notification-fonts){
    /* latin */
    @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
    }

    /* latin */
    @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
    }

    /* latin */
    @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
    }

    /* latin */
    @font-face {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/oswald/v29/TK3iWkUHHAIjg752GT8Gl-1PKw.woff2) format('woff2');
    }
}

/*************/
/** Content **/
/*************/

h1 {
    color: #000000;
    font-weight: 700;
    font-family: 'Oswald', sans-serif;
}

h2 {
    color: $primary;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
}

h3 {
    color: $primary;
    font-weight: 400;
    font-family: 'Oswald', sans-serif;
}

strong {
    color: #000000;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
}

p {
    color: #000000;
    font-weight: 300;
    font-family: 'Oswald', sans-serif;
}

ul {
    padding-left: 25px;
    font-family: 'Oswald', sans-serif;

    li {
        color: #000000;
    }   
}


.footer-content {
    color: #ffffff;
}

.signature-container {
    p {
        text-align: left !important;
        color: #000000 !important;
    }
}

/**************************************/
/** LARAVELS DEFAULT TEMPLATE BEYOND **/
/**************************************/

/* Base */

body, body *:not(html):not(style):not(br):not(tr):not(code) {
    font-family: 'Oswald', sans-serif;
    box-sizing: border-box;
}

table {
    border: 0;
}

body {
    background-color: #f5f8fa;
    color: #74787E;
    height: 100%;
    hyphens: auto;
    line-height: 1.4;
    margin: 0;
    -moz-hyphens: auto;
    -ms-word-break: break-all;
    width: 100% !important;
    -webkit-hyphens: auto;
    -webkit-text-size-adjust: none;
    word-break: break-all;
    word-break: break-word;
}

.btn {
    background-color: rgb(201,52,92);
    padding: 10px;
}

tbody tr:nth-child(even)  {
    background-color: #eee;
}

thead th {
    background-color: #ddd;
}

td {
    padding-right: 5px;
    padding-left: 5px;
    border: none !important;
}

th {
    padding-right: 5px;
    padding-left: 5px;
    border: none !important;
}

p,
ul,
ol,
blockquote {
    line-height: 1.4;
    text-align: left;
}

a {
    color: #3869D4;
}

a img {
    border: none;
}

p.sub {
    font-size: 12px;
}

img {
    max-width: 100%;
}

/* Layout */

.wrapper {
    background-color: #f5f8fa;
    margin: 0;
    padding: 0;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

.content {
    margin: 0;
    padding: 0;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

/* Header */

.header {
    padding: 25px 0;
    text-align: center;
}

.header a {
    color: #bbbfc3;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    text-shadow: 0 1px 0 white;
}

/* Body */

.body {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EDEFF2;
    border-top: 1px solid #EDEFF2;
    margin: 0;
    padding: 0;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

.inner-body {
    background-color: #FFFFFF;
    margin: 0 auto;
    padding: 0;
    width: 570px;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 570px;
}

/* Subcopy */

.subcopy {
    border-top: 1px solid #EDEFF2;
    margin-top: 25px;
    padding-top: 25px;
}

.subcopy p {
    font-size: 12px;
}

/* Footer */

.footer {
    margin: 0 auto;
    padding: 0;
    text-align: center;
    width: 570px;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 570px;
}

.footer p {
    color: #ffffff;
    font-size: 12px;
    text-align: center;
}

/* Tables */

.table table {
    margin: 30px auto;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

.table th {
    border-bottom: 1px solid #EDEFF2;
    padding-bottom: 8px;
    margin: 0;
}

.table td {
    color: #74787E;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 0;
    margin: 0;
}

.content-cell {
    padding: 35px;
}

/* Buttons */

.action {
    margin: 30px auto;
    padding: 0;
    text-align: center;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

.button {
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    color: #FFF;
    display: inline-block;
    text-decoration: none;
    -webkit-text-size-adjust: none;
}

.button-blue {
    background-color: #3097D1;
    border-top: 10px solid #3097D1;
    border-right: 18px solid #3097D1;
    border-bottom: 10px solid #3097D1;
    border-left: 18px solid #3097D1;
}

.button-green {
    background-color: #2ab27b;
    border-top: 10px solid #2ab27b;
    border-right: 18px solid #2ab27b;
    border-bottom: 10px solid #2ab27b;
    border-left: 18px solid #2ab27b;
}

.button-red {
    background-color: #bf5329;
    border-top: 10px solid #bf5329;
    border-right: 18px solid #bf5329;
    border-bottom: 10px solid #bf5329;
    border-left: 18px solid #bf5329;
}

/* Panels */

.panel {
    margin: 0 0 21px;
}

.panel-content {
    background-color: #EDEFF2;
    padding: 16px;
}

.panel-item {
    padding: 0;
}

.panel-item p:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
}

/* Promotions */

.promotion {
    background-color: #FFFFFF;
    border: 2px dashed #9BA2AB;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 25px;
    padding: 24px;
    width: 100%;
    -premailer-cellpadding: 0;
    -premailer-cellspacing: 0;
    -premailer-width: 100%;
}

.promotion h1 {
    text-align: center;
}

.promotion p {
    font-size: 15px;
    text-align: center;
}
