.dashboard-page {
	background-color: white;
	padding: 35px 50px;
	font-family: Arial, sans-serif;

	&--header {
		font-weight: 500;
		font-size: 14px;

		margin-bottom: 2em;

		&-title {
			color: $subnav-bg;
			font-weight: 600;
			font-size: 16px;
		}
	}

	&--content {
		&-spacer {

		}
	}

	&--component {
		&-title {
			font-weight: 600;
			font-family: "Oswald", sans-serif;
		}

		&-stats {
			font-size: 1.3rem;
		}

		&-content {
			&::after {
				content: '';

				position: absolute;

				left: 0;
				right: 0;
				top: 0;
				bottom: 0;

				background-color: $dark;
				opacity: 0;
				pointer-events: none;

				transition: opacity 0.25s;
			}

			&.is-loading {
				&::after {
					opacity: 0.2;

					pointer-events: all;
				}
			}
		}

		&-loader {
			position: absolute;

			top: 50% !important; //the component adds its own top...
			left: 50%;

			transform: translateX(-50%) translateY(-50%);
		}
	}
}