.tab-list {
	position: relative;
	list-style-type: none;
	top: 26px;
	margin: 0 -15px;
	padding: 0;

	&--item {
		cursor: pointer;
		position: relative;
		float: right;
		padding: 0 15px;

		font-weight: 600;
		font-size: 1rem;

		&:hover, &.active, &.active:hover {
			color: $primary;
		}

		&.active, &.active:hover {
			border-bottom: 3px solid $primary;
		}
	}
}