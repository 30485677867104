
// Fonts
@import url('https://fonts.googleapis.com/css?family=Oswald:300,400');
// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";

@import "~select2/dist/css/select2.min.css";
@import "~select2-bootstrap4-theme/dist/select2-bootstrap4.css";

@import "~flatpickr/dist/flatpickr.min.css";
@import "~flatpickr/dist/themes/airbnb.css";

@import "redactor.min.css";

//Body
@import 'base/body';

//components
@import 'components/input-toggle';
@import 'components/input-date';
@import 'components/tab-list';
@import 'components/generic-modal';
@import 'components/traffic-light';
@import 'components/notification-template-group';

//pages
@import 'pages/dashboard-page';
@import 'pages/stop';
@import 'pages/notifications';

body{
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.notification-styles {
  $use-notification-fonts: false;
  @import 'notifications';

  padding: 10px;
}

.btn-black {
  @include button-variant($black, $black)
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
  background-color: rgba(237,237,235,1);
}

.table-striped>tbody>tr:nth-child(even)>td,
.table-striped>tbody>tr:nth-child(even)>th {
  background-color: $white
}



.container-fluid{
  padding-right: 0;
  padding-left: 0;
}

.container {
  max-width: 1400px;
}

.top-row{
  background-color: $nav-bg;
}

@media (min-width: 767px){
  .left-nav{
    .nav-item{
      padding-left: 30px;
      padding-right: 30px;
      &:last-child{
        border: 0;
      }
    };

  }

}
.navbar-laravel {
  color: $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  padding-bottom: 0;
}

.nav-link{
  color: $white !important;
}

.really-awesome-logo{
  width: 120px;
  height: auto;
}

.vertically-center{
  width: 50%;
  height: 60%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.multistep-form{
  padding-left:30px;
}

.left-nav{
  margin-left: 8%;
}

.notification-square{
  height: 25px;
  width: 25px;
  background-color: $white;
  color: $black;
  margin-top: 6px;
  font-weight: 400;
  text-align: center;
  position: absolute;
}

.pm-title{
  margin-top: 35px;
  position: absolute;
}

.border-hidden{
  border: none;
}

.tooltip-inner {
  text-align: left;
}

.login-box{
  height: 80%;
}

.background-login{
  background-color: $nav-bg;
}

.heavy-label{
  font-weight: 400;
}
.light-label{
  font-weight: 300;
}

.forgot-your-pass{
  padding-top: 10px;
  a{
    color: $white;
  }
}

.secondary-nav-row{
  background-color: $subnav-bg;
  color: $white;
}

.nav-item.active{
  font-weight: 400;
  text-decoration: underline;
}

.add-new-button{
  margin-top: 1px;
  padding-top: 7px;
  padding-bottom:7px;
}

.page-title{
  font-weight: 400;
}
.page-content{
  padding-top: 20px;
}

.product-information{
  background-color: rgba(237,237,235,1);
}

.product-pricing{
  background-color: $white;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.top-padding{
  padding-top: 20px;
}
.half-top-padding{
  padding-top: 10px;
}
.half-bottom-padding{
   padding-bottom: 10px;
 }
#myApp{
  padding-bottom: 30px;
}

.sizenamelabel{
  margin-left: 3px;
}

.information-btn{
  background-color: $primary;
  color: $white;
  display: block;
  height: 14px;
  text-align: center;
  font-size: 8px;
  width: 14px;
  margin-top: 6px;
  &:hover{
    color: $white;
    text-decoration: none;
  }
}

#pound-addon{
  border-radius: unset;
  background-color: rgba(167,165,152,1);
  font-weight: 300;
  color: $white;
}

.my-hr{
  margin-left:0;
}

.no-br{
  border-radius: unset;
}

.delete-size-btn{
  margin-top: 30px;
}

.left-padding{
  padding-left: 15px;
}

.badge-primary{
  font-weight: 400;
  color: $white;
}
.badge-dark{
  font-weight: 400;
  color: $white;
}

.bottom-card{
  background-color: rgba(237,237,235,1);
  padding-top: 8px;
  text-align: center;
}

.big-number{
  font-size: 24px;
  display: block;
}

.card-drivers-col {
  border-right: 1px solid black;
}

.card-routes-col {
  border-left: 1px solid black;
}

.card-subtitle{
  font-size: 12px;
}

.card-top-padding{
  padding-top: 8px;
}
.pg-l{
  padding-bottom: 25px;
}

.margin-left-5{
  margin-left: 5px;
}

.subtitle-top-bar{
  min-height: 70px;
  width:100%;
  background-color: rgba(237,237,235,1);
}
#stb-title{
  margin: 30px;
  margin-bottom: 15px;
}

#stb-circles{
  margin-left: 30px;
}
.circle-active{
  color: $primary;
}

.circle-completed{
  color: rgba(201,52,92,0.4);
}

.circle-incomplete{
  color: white;
}

.circle-text-incomplete{
  color:$primary;
}

.multistep-form{
  padding-top: 20px;
  padding-bottom:20px;
}

.routemap{
  height:180px;
}

.middle-card{
  padding-top:0;
  padding-bottom: 0;
}

.franchise-main{
  background-color: $white;
}
.franchise-sidebar{
  padding-bottom: 20px;
  background-color: rgba(237,237,235,1);
}
.franchise-map{
  margin-top:20px;
  margin-bottom:20px;
  height:300px;
}

.btn-grey {
  color: #FFFFFF;
  background-color: #A7A598;
  border-color: #A7A598;
}

.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  color: #FFFFFF;
  background-color: #53524c;
  border-color: #A7A598;
}

.btn-grey:active,
.btn-grey.active,
.open .dropdown-toggle.btn-grey {
  background-image: none;
}

.btn-grey.disabled,
.btn-grey[disabled],
fieldset[disabled] .btn-grey,
.btn-grey.disabled:hover,
.btn-grey[disabled]:hover,
fieldset[disabled] .btn-grey:hover,
.btn-grey.disabled:focus,
.btn-grey[disabled]:focus,
fieldset[disabled] .btn-grey:focus,
.btn-grey.disabled:active,
.btn-grey[disabled]:active,
fieldset[disabled] .btn-grey:active,
.btn-grey.disabled.active,
.btn-grey[disabled].active,
fieldset[disabled] .btn-grey.active {
  background-color: #A7A598;
  border-color: #A7A598;
}

.btn-grey .badge {
  color: #A7A598;
  background-color: #FFFFFF;
}

.bg-darkpink {
  background-color: $subnav-bg !important;
}

.btn-darkpink {
  color: #FFFFFF;
  background-color: rgb(160,41,73);
  border-color: rgb(160,41,73);
}

.btn-darkpink:hover,
.btn-darkpink:focus,
.btn-darkpink:active,
.btn-darkpink.active,
.open .dropdown-toggle.btn-darkpink {
  color: #FFFFFF;
  background-color: darken(rgb(160,41,73),10);
  border-color: rgb(160,41,73);
}

.btn-darkpink:active,
.btn-darkpink.active,
.open .dropdown-toggle.btn-darkpink {
  background-image: none;
}

.btn-darkpink.disabled,
.btn-darkpink[disabled],
fieldset[disabled] .btn-darkpink,
.btn-darkpink.disabled:hover,
.btn-darkpink[disabled]:hover,
fieldset[disabled] .btn-darkpink:hover,
.btn-darkpink.disabled:focus,
.btn-darkpink[disabled]:focus,
fieldset[disabled] .btn-darkpink:focus,
.btn-darkpink.disabled:active,
.btn-darkpink[disabled]:active,
fieldset[disabled] .btn-darkpink:active,
.btn-darkpink.disabled.active,
.btn-darkpink[disabled].active,
fieldset[disabled] .btn-darkpink.active {
  background-color: rgb(160,41,73);
  border-color: rgb(160,41,73);
}

.btn-darkpink .badge {
  color: rgb(160,41,73);
  background-color: #FFFFFF;
}


.btn-margin-bottom{
  margin-bottom: 5px;
}

.spacing-span{
  width: 25px;
}

.reportholder{
  background-color: white;
  padding: 20px;
}

.routedetails{
  font-weight: 500;
  font-size: 14px;
}

.totals{
  margin-top: -30px;
  padding-top: 30px;
  padding-bottom: 15px;
}
.sumup{
  padding-top: 15px;
}
.charts-nav .graph-nav{
  border-color: rgb(160,41,73);
  background-color: #A7A598;
  .nav-link{
    color: rgb(160,41,73) !important;
  }
}
.charts-nav .graph-nav .active{
  background-color: rgb(160,41,73);
  border-color: rgb(160,41,73);
  color: white !important;
}

#transactions-per-site-chart{
  margin-bottom: 30px;
}

.stats{
  margin-top: 30px;
  margin-bottom: 30px;
}

.search-form-report{
  padding-top:5px;
}
.download-csv-report{
  padding-top:3px;
}

.transaction-stats{
  background-color: black;
  color: white;
  padding-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
 }

.pie-chart-row{
  padding-top: 50px;

}
.profit-stats{
  padding-top:95px;
}

.pie-chart-row-title{
  padding-left: 35px;
}

.sss-row-title{
  padding-left: 35px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.graph-position{
  margin-top: -70px;
}

.sss-table{
  padding-left: 45px;
}

.total-subtext{
  font-size: 11px;
  color: #A7A598;
}

#show-impersonation {
  position: fixed;
  right: 5px;
  bottom: 5px;
  cursor: pointer;

  &.active {
    + .impersonation {
      bottom: 30px;
    }
  }
}

.impersonation{
  position: fixed;
  //bottom: 30px;
  right: 30px;
  width: 320px;
  height: 100px;
  padding: 15px;
  background-color: #f2f2f2;
  border: 5px solid #c9345c;
  z-index: 2;
  bottom: -320px;
  transition: bottom 0.25s;
}

#impersonation-box{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.product-sale-row{
  background-color: rgba(167,165,152,0.5);
}

.transaction-row{
  background-color: rgba(201,52,92,0.5);
}

.loadingSpinnerContainer{
    position:absolute;
    width:100%;
    height:100%;
    background-color:rgba(0,1,1,0.2);
    display:flex;
    align-items:center;
    z-index:1;
}
.chartHeight {
    height: 800px;
}
@media only screen and (max-width: 600px) {
    .chartHeight {
        height: 400px;
    }
}

.h-35px {
    height: 35px;
}

.select_route_multiple {
    border: 1px solid black;
    border-radius: 0.25rem;
    margin-bottom: 0.25rem;
    background-color: #ddd;
}
.rounded-left-only {
    border-radius: 0 !important;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-right-only {
    border-radius: 0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-top-only {
    border-radius: 0 !important;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-bottom-only {
    border-radius: 0 !important;
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.card-deck .card {
  display: block;
  flex-basis: 25%;
}

.highlight-first {
  border: 1px solid red;
}

.drink-loyalty{
  font-size: 8px;
  color:rgb(201,52,92);
}
.food-loyalty{
  font-size: 8px;
  color:rgba(30, 144, 255, 0.5);
}

.product-preorderable {
  font-size: 8px;
  color: $secondary;
}

.dropzone {
  width: 100%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
  }

  .dropzone-no-images, .dropzone-images {
    margin-top: 5px;
    flex-grow: 1;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 0.25rem;
  }

  .dropzone-images {
    flex-wrap: wrap;
    justify-content: flex-start;

    .dropzone-image-wrapper {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: 5px;

      @include media-breakpoint-down(xs){
        width: 50%;
      }

      .dropzone-image {
        width: 100%;
        flex-grow: 1;
      }

      .dropzone-file-name {
        text-align: center;
        background-color: $light;
        word-wrap: break-word;
      }

    }

    &--single {
      .dropzone-image-wrapper {
        width: 100%;
      }
    }
  }
}

.min-max-cell {

  &.highest {
    background-color: #c3d79a !important;
  }

  &.lowest {
    background-color: #da9695 !important;
  }

  &--light {
    &.highest {
      background-color: #ecf0df !important;
    }

    &.lowest {
      background-color: #f1dcdb !important;
    }
  }
}

.dropped-row {
  color: #adada2 !important;
}

.adhoc-event-cell {
  &.final {
    border-top: 1px solid #dee2e6;
  }

  border-bottom: 1px solid $primary;
}

#target-csv {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.target-csv-upload {
  background-color: $light;
  width: 200px;
  height: 36px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: pointer;
  transition: 0.2s background-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: darken($light, 5%);
  }

  + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.chart-wrapper {
  button {
    top: 10px;
    right: 10px;
  }
}

.progress-content-block {
  span {
    padding-bottom: 10px;
    &:first-child, &:last-child {
      display: block;
      width: 50px;
    }
  }
}

.current-progress {
  bottom: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.half-and-half {
  position: relative;
  span {
    position: absolute;
    width: 8px;
    height: 16px;
      top: 0;

    &:first-child {
      left: 0;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    &:last-child {
      right: 0;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
}

.salesbreakdown-dates {
  input {
    width: 100px !important;
  }
}

.notification__show--image {
  width: (100% / 3);
  padding: 2px;
}

.notification-stops {
  > .select2 {
    flex-grow: 1;

    .select2-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.select2-selection--multiple .select2-selection__choice {
        margin-bottom: 0.5rem;
      }
    }
  }

  > button.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.notification-viewed-card {
  td {
    span {

    }

    em {
      font-size: 12px;
    }
  }
}

.notification-search {
  @include media-breakpoint-down(sm){
    .d-flex {
      flex-wrap: wrap;

      select {
        width: 100%;
      }
    }
  }
}

.leaflet-container {
  z-index: 0;
}

.is-invalid { //important so it can overwrite redactors border
  border: 1px solid $danger !important;
}

select[readonly].select2-hidden-accessible + .select2-container {
  pointer-events: none;
  touch-action: none;

  .select2-selection {
    background: $input-disabled-bg;
    box-shadow: none;
  }

  .select2-selection__arrow,
  .select2-selection__clear {
    display: none;
  }
}

.route-dropdown {
  max-width: 150px;
}

.preorders__dropzone {
  &.has-images {
    > .dropzone:not(.dropzone--placeholder) {
      display: none;
    }

    .dropzone-images {
      background-color: $white;
    }
  }

  &.remove-image {
    > .dropzone:not(.dropzone--placeholder) {
      display: flex;
    }

    > .dropzone.dropzone--placeholder {
      display: none;
    }
  }

  .dropzone--placeholder {
    position: relative;
  }

  &-remove {
    position: absolute;

    top: 15px;
    right: 10px;

    width: 16px;
    height: 16px;

    margin: 0;

    &::before, &::after {
      content: '';

      cursor: pointer;

      position: absolute;

      background-color: $danger;

      width: 20px;
      height: 2px;

      top: 8px;
      right: -2px;

      transform-origin: 50%;
    }

    &::before {
      transform: rotateZ(45deg)
    }

    &::after {
      transform: rotateZ(-45deg)
    }
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.cursor-pointer {
  cursor: pointer;
}