.traffic-light {
    width: 24px;
    height: 24px;
    display: inline-block;
    border: 2px solid $black;
    border-radius: 50%;

    &--unassigned {
        background-color: #FFFFFF;
    }

    &--green {
        background-color: #13E99C;
    }

    &--amber {
        background-color: #FD9412;
    }

    &--red {
        background-color: $primary;
    }

    &--black {
        background-color: $primary;
        border: 6px solid $black;
    }

    &--black-square {
        background-color: $primary;
        border: 6px solid $black;
        border-radius: 5px;
    }

    &--dropped {
        background-color: #000000;
    }
}

.tooltip.vue-tooltip-theme {
 opacity: 100;
}
