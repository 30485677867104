.input-date {
	display: flex;
	margin-left: 2em;

	&--label {
		margin: auto 1em auto auto;
	}

	&--picker_wrapper{
		max-width: 100px;
	}

	&--img {
		background-color: $primary;
		padding: 5px 10px;
		margin-left: 5px;
	}
}