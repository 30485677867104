.notification-template-group {
    &__list {
        list-style-type: none;

        padding-left: 0;

        &--item {
            &.active {
                color: $primary;
            }
        }
    }
}
