.notification {
    &__sidebar {
        background-color: #eeeeeb;
    }

    &__form {
        background-color: $white;

        .dropzone .dropzone-no-images {
            border: 1px solid $input-border-color;
        }
    }
}
