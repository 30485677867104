.generic-modal {
    position: absolute;

    max-width: 100%;
    width: 500px;

    top: 50%;
    left: 50%;

    transition: transform 0.25s;
    transform: translateY(-100vh) translateX(-50%);


    background-color: $white;

    &__container {
        position: fixed;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: rgba($black, 0.6);

        z-index: 101;

        transition: opacity 0.25s;
        opacity: 0;

        pointer-events: none;

        &.active {
            opacity: 1;
            pointer-events: all;

            .generic-modal {
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    &__header{
        padding: 1rem;

        background-color: $black;
        color: $white;

        text-align: center;

        &--close {
            cursor: pointer;
        }
    }

    &__content {
        padding: 3rem 1rem;

        &--title {
            font-weight: $font-weight-bold;
            font-size: 20px;
            text-align: center;

            margin-bottom: 1rem;
        }

        &--content {
            text-align: center;
        }
    }

    &__button-group {
        display: flex;
        justify-content: center;

        margin-top: 2rem;

        > * {
            &:not(:first-child){
                margin-left: 0.5rem;
            }

            &:not(:last-child){
                margin-right: 0.5rem;
            }
        }
    }
}
