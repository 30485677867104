.input-toggle {
	display: inline-flex;
	border: 1px solid $primary;

	&--item {
		padding: 5px 20px;
		cursor: pointer;

		border-left: 1px solid $primary;
		border-right: 1px solid $primary;

		&:last-child {
			border-left: none;
			border-right: none;
		}
		&:first-child {
			border-left: none;
			border-right: none;
		}

		&.active {
			color: white;
			background-color: $primary;
		}
	}
}