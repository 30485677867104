

// Body
$body-bg: rgb(233,231,216);
$table-bg-1: rgb(255,255,255);
$table-bg-2: rgb(237,237,235);
$nav-bg: rgb(201,52,92);
$subnav-bg: rgb(160,41,73);
$table-head-bg: rgb(0,0,0);

$primary: rgb(201, 52, 92);


// Typography
$font-family-sans-serif: "Oswald", sans-serif;


$font-size-base: 0.9rem;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Panels
$panel-default-heading-bg: #fff;